(function (e) {
    e(jQuery, window, document);
})(function ($, window, document) {
    // console.log("init");
    var app = {
        init: function ($) {
            app.YT()
        },
        YT: function(){
            if($("#videoBg").length) $("#videoBg").YTPlayer();
        }
    }
    // Call your function onload
    $(document).ready(function () {
        app.init($);
    });
    // Call your function onresize
    $(document).resize(function () {
        app.init($);
    });
});